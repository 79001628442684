import React from "react";
import { connect } from 'react-redux'
import './css/Notas.css'
import Masonry from "react-masonry-css";
import CriarNotaForm from './CriarNotaForm'
import Nota from './Nota'


const NotasDeletadas = (props) => {




  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    500: 2,
    300: 1
  };



  return (
    <div className="Notas">

      <h1 className="DeleteHeader">Notas Excluídas</h1>

      <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column"   >
        {props.Notas.map((Note) => {
          return (
            <Nota

              key={Note.ID}
              Note={Note}
            />
          );
        })}
      </Masonry>

     
    </div>
  );
}

const ConnectedNotasDeletadas = connect((state) => {
  return {
    LoggedUser: state.LoggedUser,
    Notas: state.Notas.filter(Nota => {
      return Nota.status === 'Deletada'
    })
  }
})(NotasDeletadas)

export default ConnectedNotasDeletadas

