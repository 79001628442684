import React, { useState } from "react";
import { connect } from 'react-redux'
import './css/Notas.css'
import Masonry from "react-masonry-css";
import CriarNotaForm from './CriarNotaForm'
import Nota from './Nota'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Notas = (props) => {

  //console.log(props.Notas)

  const [NotasList, setNotasList] = useState([...props.Notas])


  const breakpointColumnsObj = {
    default: 5,
    1100: 4,
    950: 3,
    500: 2,
    300: 1
  };


  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const IndexSource = result.source.index;
    const IndexDestination = result.destination.index;

    const copiedItems = [...NotasList];

    const [removed] = copiedItems.splice(IndexSource, 1);
    copiedItems.splice(IndexDestination, 0, removed);

    //column.items = [...copiedItems]
    console.log(copiedItems)


    setNotasList([...copiedItems]);

  };


  return (
    <div className="Notas">

      <CriarNotaForm />

      <DragDropContext onDragEnd={(result) => {
        onDragEnd(result)
        //console.log(result)
      }}>

        <Droppable droppableId={'sasbansbahs'} key={'sasbansbahs'}>
          {(provided, snapshot) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>

                <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column"   >


                  {NotasList.map((Note, index) => {
                    return (

                      <Draggable key={Note.ID} draggableId={Note.ID} index={index} >
                        {(provided, snapshot) => {
                          return (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <Nota key={Note.ID} Note={Note} />
                            </div>

                          )
                        }}

                      </Draggable>
 

                    );
                  })}


                </Masonry>

              </div>
            );
          }}
        </Droppable>




      </DragDropContext>

    </div>
  );
}

const ConnectedNotas = connect((state) => {
  return {
    LoggedUser: state.LoggedUser,
    Notas: state.Notas.filter(Nota => {
      return Nota.status === 'Ativa'
    })
  }
})(Notas)

export default ConnectedNotas

